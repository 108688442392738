<template>
  <page class="not-found-page">
    <!-- Hero -->
    <simple-hero localeRootPath="pages.notFound.hero" />
  </page>
</template>

<script>
import page from '@/mixins/page'
import SimpleHero from '@/sections/premade/SimpleHero'

export default {
  meta () {
    return {
      title: this.$locale('pages.home.pageTitle')
    }
  },

  mixins: [
    page
  ],

  components: {
    SimpleHero
  }
}
</script>

<style lang="sass">
.not-found-page
</style>
